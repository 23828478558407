<!-- <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button> -->
<div>
  <div *ngIf="terminal_modal == 'true'"  class="terminal-overlay"></div>
  <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
</div>
<ion-content>
  <!-- <div *ngIf="store_data == undefined" class="loader">

    <img src="../../assets/image/ella-loader1.gif"
      alt="">
  </div>
  <div *ngIf="routeToHome == 'false'" [innerHTML]="pageImage" ></div>
  <div *ngIf="routeToHome == 'true'">
    
    <div [innerHTML]="kiosk_start_screen_image" alt="" routerLink="/home" style="height: 100vh;"></div>
  </div> -->
  <div *ngIf="shop && shop.master_restaurant_status === 'M_ON' && appSettings"
  [innerHTML]=" app_maintanance_description2" class="maintaince">
</div>

<div *ngIf="shop && shop.master_restaurant_status === 'ELLA_M_ON' && appSettings"
  [innerHTML]=" kiosk_start_screen_image" class="ella-maintaince" routerLink="/home" style="height: 100vh;">
</div>

<div *ngIf="shop && shop.master_restaurant_status === 'ACTIVE' && appSettings  && is_restaurant_closed_status=='YES' "
  [innerHTML]="app_store_closed_description2" class="full-img">
</div>

<div *ngIf="is_maintaince_mode_on == 'false' && is_restaurant_closed_status=='NO' && is_ella_maintaince_mode_on == false">
    <div  [innerHTML]="kiosk_start_screen_image" alt="" routerLink="/home" style="height: 100vh;"></div>
</div>



</ion-content>

