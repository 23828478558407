<!-- <ion-header>
  <ion-toolbar mode="md">
    <ion-buttons slot="start" mode="ios">
      <ion-button (click)="goBack()" class="white-icon"></ion-button>
    </ion-buttons>
    <ion-title mode="ios">{{ 'PLEASE_SELECT.carte' | translate:params }}</ion-title>
  </ion-toolbar>
</ion-header> -->
<ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn">  </ion-button>
<ion-content>
 
  <div class="complete">
    <img src="assets/image/payment-complete.svg" alt="" class="success">
    <h5>Please collect your QR code receipt and scan it once your order is ready for collection</h5>
    <!-- <h2>{{ 'SETTLEMENT_COMPLETE.payment_complete' | translate:params }}</h2>
    <h5>{{ 'SETTLEMENT_COMPLETE.qr_para' | translate:params }}</h5> -->
    
    <div class="order">
      <span>Order No:</span>
      <h4>{{render_order_number}}</h4>
      <img src="../../assets/image/scan.svg" alt="">      
      
    </div>

    <!-- <ion-button (click)="new_order()" [hidden]="messageSuccess">Start New Order</ion-button> -->
     
    <!-- <div class="status">
      <ion-row>
        <ion-col size="6">
          <span>{{ 'PRODUCT_METHOD.transportation_payment' | translate:params }}</span>
          <h3 *ngIf="showDecimal">{{currency}} {{orderInfo?.order_info_grand_total | number:'1.2-2'}}</h3>
          <h3 *ngIf="showDecimal == false">{{currency}} {{orderInfo?.order_info_grand_total | number:'1.0-0'}}</h3>
        </ion-col>
        <ion-col size="6">
          <span>{{ 'SETTLEMENT_COMPLETE.current_balance' | translate:params }}</span>
          <h3 *ngIf="showDecimal">{{currency}} {{paymentResponse.balance | number:'1.2-2'}}</h3>
          <h3 *ngIf="showDecimal == false">{{currency}} {{paymentResponse.balance | number:'1.0-0'}}</h3>
        </ion-col>
      </ion-row>
    </div> -->

    
    <img src="../../assets/image/love-byte.svg" alt="" class="love-byte">  


    
  </div>

</ion-content>
