
<ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn">  </ion-button>
<ion-content>

  <img src="../../assets/image/love-bite-tv.svg" alt="" style="margin: 50px auto 20px;display: block;">

  <section class="order">

    <div class="prepare">
      <label><img src="assets/image/prepare.svg" alt="">
        <!-- {{ 'TV_SCREEN.preparation' | translate:params }} -->
        Preparing Order
      </label>

   
      <!-- <ion-list>
        <ion-item *ngFor="let item of orders">{{item.label}}</ion-item>
      </ion-list> -->

      <div class="orders">
        <div class="item" *ngFor="let item of order_response['pending']">
          <h4> {{item.order_no}}</h4>
        </div>

        
      </div>
    </div>

    <div class="ready">
      <label><img src="assets/image/ready.svg" alt="">
        <!-- {{ 'TV_SCREEN.ready' | translate:params }} -->
        Ready to Collect
      </label>
      
      
      <!-- <h2>POP BOX Collection</h2> -->
      <div class="orders">        
        <div class="item" *ngFor="let item of order_response['completed']">
          <!-- <span>A</span> -->
          <h4>{{item.order_no}}</h4>
           <span>({{item.collect_from}})</span>
        </div>
        <!-- <div class="item pink">
          <span>Z</span>
        </div> -->
      </div>

      <!-- <h2 style="margin-top: 25px;">Self Collection</h2> -->
      <!-- <div class="orders self-collected">        
        <div class="item">
          <h4>001</h4>
        </div>  

      </div> -->
    </div>

  </section>

</ion-content>

<!-- <ion-footer>
  <ion-toolbar mode="md">
    <ion-title>
      <ion-slides pager="true" [options]="slideOpts">
        <ion-slide>
          <img src="../../assets/image/Banner3.gif" alt="">
        </ion-slide>
        <ion-slide>
          <img src="../../assets/image/Banner4.png" alt="">
        </ion-slide>
      </ion-slides>
    </ion-title>
  </ion-toolbar>
</ion-footer> -->
