

<ion-content class="ion-padding">

  <div class="box">
    <img src="../../../assets/image/bell.svg" alt="">
    <h3>Payment Not Processed</h3>
    <p>Click "Ok" back to homepage</p>
    <span>{{time_left}}s</span>
    <ion-button (click)="dismiss()">OK</ion-button>
  </div>

</ion-content>
