<ion-header>
  <ion-toolbar>
    <ion-title>Details of the set menu</ion-title>
    <ion-buttons slot="end" >
      <ion-button (click)="dismissModal()">
        <img src="assets/image/cross-circle.svg" alt="">
      </ion-button> 
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list >
    <ion-item lines="none">
      <ion-avatar class="product-av-wrap">
        <img class="product-av" src="{{itemDetail[0].thumb}}"/>
      </ion-avatar>
      <ion-label class="food-item">
        <h2>{{itemDetail[0].name}}</h2> 
      </ion-label>
    </ion-item>
    <br>
    <ion-item lines="none">
      <ion-avatar class="product-av-wrap">
        <img class="product-av" src="{{itemDetail[1].thumb}}"/>
      </ion-avatar>
      <ion-label class="food-item">
        <h2>{{itemDetail[1].name}}</h2>
      </ion-label>
    </ion-item>
  </ion-list> 
</ion-content> 
