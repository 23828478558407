import { Component, OnInit } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { UtilityService } from '../services/utility.service';
import { environment } from 'src/environments/environment';
import { KioskApiService } from '../services/kiosk-api.service';
import { getAnalytics, logEvent } from "firebase/analytics";
import { AuditLogService } from '../services/audit-log.service';
import { images_display_timer, terminalCheck } from '../common/api';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  is_maintaince_mode_on = "true";
  appSettings: any;
  shop: any;
  settings = {};
  is_restaurant_closed_status = "NO";
  alertController: any;
  store_close_status;
  restaurant_mode;
  obj_restaurant_status;
  obj_restaurantDetails;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  counter = 0
  telemetry_poller;
  terminal_modal;
  showDiv: boolean
  pageImage = "";
  routeToHome = "false"
  store_info: any;
  screen_image_timer;
  store_data;
  kiosk_start_screen_image:any;
  display_images:any;
  is_ella_maintaince_mode_on:boolean

  constructor(private router: Router,
    private store: LocalStoreService,
    private kioskauthservice: KioskauthService,
    private utilityService: UtilityService,
    private kiosk_apiService : KioskApiService,
    private auditlogservice : AuditLogService) { 
     
    // this.getRestaurantDetails();
    // this.is_restaurant_closed();
    this.is_maintaince_mode_on = "true";
    this.is_restaurant_closed_status = "NO"
   
    }

  ionViewWillEnter() {
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    // this.display_images =setInterval(()=>{
    //   this.getAppSettings();
    // },images_display_timer)

    this.is_maintaince_mode_on = "true";
    this.is_restaurant_closed_status = "NO"
    this.getRestaurantDetails();
    this.is_restaurant_closed();
    this.getAppSettings();
    this.store_close_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);
    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
      // this.screen_image_timer = setInterval(
      //   ()=>{
      //     this.Scan_image()
      //   },5000
      // )
      // this.Scan_image()

      this.audit_log()
  }

  ngOnInit() {
    this.is_maintaince_mode_on = "true";
    this.is_restaurant_closed_status = "NO"
  }

  is_restaurant_closed() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid") 
   this.obj_restaurant_status = this.kiosk_apiService.restaurant_close_status(master_restaurant_uuid).subscribe(res => {

          if (res.statusCode == 200) {
            if (res.aaData == false) {
              this.is_restaurant_closed_status = "YES";
            } else {
              this.is_restaurant_closed_status = "NO";
            }
          }


          if (res.statusCode != 200) {
            if (res.aaData == false) {
              this.technical_error()
            }
          }
        },
      );
  }

  getRestaurantDetails() {
   this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details().subscribe(res => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store_info = res.aaData
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }

          // console.log("this.store", this.store_info)
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (this.shop.master_restaurant_status == 'ACTIVE') {
            this.is_maintaince_mode_on = "false";
          }else if (this.shop.master_restaurant_status != 'M_ON'){
            this.is_maintaince_mode_on = "false";
          }
          else {
            this.is_maintaince_mode_on = "true";
          }     
          
        }

       

        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
      }, error => {
        this.technical_error()
     
      });
  }

  getAppSettings() {
    this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.kiosk_start_screen_image = response.aaData.app_settings.app_store_scan_description_kiosk
      }
    }, err => {

    });
  }

  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
  }

  

  ionViewDidLeave() {
    clearInterval(this.store_close_status);
    clearInterval(this.restaurant_mode);
    clearInterval(this.telemetry_poller);
    this.obj_restaurantDetails.unsubscribe();
    this.obj_restaurant_status.unsubscribe();
    clearInterval(this.terminal_modal)
    clearInterval( this.display_images)
  }

  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [

        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/Scan');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/Scan'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

  audit_log(){
    this.auditlogservice.logEvents("SCAN");
    this.auditlogservice.audit_log("SCAN");
  }

  
  Scan_image(){
    this.store_data = this.store_info
    // console.log( "this.is_restaurant_closed_status", this.store_data)
    // console.log("this.is_maintaince_mode_on", this.is_maintaince_mode_on)
        if(this.is_maintaince_mode_on == "true"){
          // console.log("1st",this.routeToHome )
          // if(this.is_restaurant_closed_status === "YES" ){
          this.routeToHome = "false"
          this.pageImage =  this.app_maintanance_description2;
          // }else{
          //   this.pageImage =  this.app_maintanance_description2;
          // }
        }
        else if (this.is_restaurant_closed_status === "YES" && this.store_info.master_restaurant_status === "ACTIVE"){
          // console.log("2nd",this.routeToHome )
          this.routeToHome = "false"
          this.pageImage = this.app_store_closed_description2
        }
        else if (this.is_maintaince_mode_on == "false" && this.is_restaurant_closed_status === "NO" ){
          // console.log("third",this.routeToHome )
          this.routeToHome = "true"
          this.pageImage =  this.kiosk_start_screen_image
        }
        else{
          // console.log("else", this.routeToHome )
          this.routeToHome = "true"
          this.pageImage = this.kiosk_start_screen_image
        }
    
      }

}
