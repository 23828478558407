import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule} from '@angular/common';

import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CartItemDetailPageModule } from './cart-item-detail/cart-item-detail.module';
import { ComboAddonsPageModule } from './combo-addons/combo-addons.module';
import { HomePageModule } from './home/home.module';
import { KioskautologinPageModule } from './kioskautologin/kioskautologin.module';
import { LogoutPageModule } from './logout/logout.module';
import { PaymentMethodPageModule } from './payment-method/payment-method.module';

import { ProductComboDetailPageModule } from './product-combo-detail/product-combo-detail.module';
import { ProductDetailPageModule } from './product-detail/product-detail.module';
import { ScanPageModule } from './scan/scan.module';
import { SettlementCompletePageModule } from './settlement-complete/settlement-complete.module';

import { TvScreenPageModule } from './tv-screen/tv-screen.module';
import { KioskauthService } from './services/kioskauth.service';
import { AuthGuard } from './services/auth.guard';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpLoaderFactory = (httpClient: HttpClient) => new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent,],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    CartItemDetailPageModule,
    ComboAddonsPageModule,
    HomePageModule,
    KioskautologinPageModule,
    LogoutPageModule,
    PaymentMethodPageModule,
  
    ProductComboDetailPageModule,
    ProductDetailPageModule,
    ScanPageModule,
    SettlementCompletePageModule,
   
    TvScreenPageModule


    ],
  providers: [KioskauthService,AuthGuard,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(public translate: TranslateService){
    this.translate.setDefaultLang('en');
  }
}
