<ion-header  (click)= "reset_time()">
  <ion-toolbar mode="md">
    <ion-buttons slot="start" mode="ios">
      <ion-back-button icon="" text="" class="white-icon"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">{{item?.master_menu_item_name}}</ion-title>
    <div>
      <div *ngIf="terminal_modal == 'true'"  class="terminal-overlay"></div>
      <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content (click)= "reset_time()" [scrollEvents]="true" 
(ionScrollStart)="logScrollStart($event)"
(ionScroll)="logScrolling($event)" 
(ionScrollEnd)="logScrollEnd($event)">

  <div *ngFor="let tot of totalQuantity;">
    
    <div class="item-box">
      <span><img src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}" alt=""></span>
      <!-- <h4>{{item?.master_menu_item_name}}</h4>  -->
    </div>

 
    <ion-accordion-group  value="description">
      <ion-accordion value="description" toggle-icon="arrow-down-circle">
        <ion-item slot="header" lines="none">
          <ion-label><img src="../../assets/image/description_icon.svg" alt=""> Description</ion-label>
        </ion-item>
    
        <ion-list slot="content">
         <p class="m-0">{{item?.master_menu_item_desc}}</p>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>

    <!-- <ion-list class="description">

      <ion-item slot="header" lines="none">
        <ion-label>
          <h2>
            <button type="button" class="accordion-button" style="padding-bottom: 10px !important;">Description</button>
          </h2>
        </ion-label>
      </ion-item>
      <p class="m-0">{{item?.master_menu_item_desc}}</p>
    </ion-list> -->

    <div *ngIf="item">
      <div *ngFor="let items1 of item.add_ons | keyvalue; index as k">
        <ion-list *ngFor="let add_on of items1.value | keyvalue; ">
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2>
                <button type="button" class="accordion-button" style="padding-bottom: 0 !important;">
                  {{add_on.key}}

                </button>
              </h2>
              <div class="labelDesc">{{add_on.value[0]['master_addon_group_label']}}</div>
            </ion-label>
          </ion-item>

          <ion-list slot="content" class="category">
            <div *ngIf="groupSectiontypeIsMultiple(add_on.value[0]['master_addon_group_selection_type']) == false">
              <ion-card class="full-line">

                <ion-radio-group value="{{addons_select[add_on.value[0]['master_addon_group_uuid']]}}_{{tot}}"
                  (ionChange)="changeAddonTotal($event,tot,add_on.key)">
                  <ion-item *ngFor="let addon of add_on.value" lines="none">

                    <ion-radio (click)="store_in_local($event,addon,tot)" id="{{addon?.master_addon_internal_name}}"
                      slot="start" mode="md" value="{{addon?.master_addon_internal_name}}_{{tot}}"></ion-radio>
                    <ion-label>

                      {{addon?.master_addon_name}}
                      <div class="ml-auto" id="{{addon?.master_addon_internal_name}}_{{tot}}">
                        <span *ngIf="addon?.master_addon_price > 0">
                          {{currency}}{{addon?.master_addon_price}}
                        </span>
                      </div>

                    </ion-label>
                  </ion-item>
                </ion-radio-group>
              </ion-card>
            </div>
            <div *ngIf="groupSectiontypeIsMultiple(add_on.value[0]['master_addon_group_selection_type'])">
              <ion-card class="full-line">
                <ion-item lines="none" *ngFor="let addon of add_on.value">
                  <ion-label>
                    {{addon?.master_addon_name}}
                    <div class="ml-auto" id="{{addon?.master_addon_internal_name}}">
                      <span *ngIf="addon?.master_addon_price > 0">
                        {{currency}}{{addon?.master_addon_price}}
                      </span>
                    </div>
                  </ion-label>
                  <ion-checkbox (click)="store_in_local($event,addon,tot)" id="{{addon?.master_addon_uuid}}"
                    slot="start" mode="md" (ionChange)="changeAddonTotal($event,tot,add_on.key)"
                    value="{{addon?.master_addon_internal_name}}">
                  </ion-checkbox>
                </ion-item>
              </ion-card>
            </div>
          </ion-list>
        </ion-list>
        <div class="option"
        *ngIf="item.master_menu_item_is_combo == 'YES' && item.master_food_category_uuid != '0ecd423c3da7fa02266ae19af4b4cdb'">
        <ion-row>
          <ion-col>
            <div class="box" detail="false">
              <span style="background: #61BE7B;"><img src="../../assets/image/Bytes_ala_carte.png" alt=""></span>
              <h4>{{ 'PLEASE_SELECT.carte' | translate:params }}</h4>
            </div>
          </ion-col>
          <ion-col (click)="redirectOnComboItemDetailPage()">
            <div class="box" detail="false">
              <span><img src="../../assets/image/bytes_combo.png" alt=""></span>
              <h4>{{ 'PLEASE_SELECT.set_menu' | translate:params }}</h4>
            </div>
          </ion-col>
        </ion-row>
      </div>

      </div>

    </div>


  </div>
</ion-content>


<ion-footer>
  <ion-toolbar mode="md">
    <ion-title>
      <ion-button *ngIf ="item.master_food_category_uuid != '0ecd423c3da7fa02266ae19af4b4cdb'"   class="d-flex align-items-center" (click)="addToCart(item)">     
        {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}}{{total | number:'1.2-2'}}
      </ion-button>
      <ion-button *ngIf ="item.master_food_category_uuid == '0ecd423c3da7fa02266ae19af4b4cdb'"   class="d-flex align-items-center" (click)="redirectOnComboItemDetailPage()">    
     CONTINUE
      </ion-button>
    </ion-title>
  </ion-toolbar>
</ion-footer>