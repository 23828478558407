<ion-header (click)="reset_time()">
  <ion-toolbar mode="md">
    <ion-buttons slot="start" mode="ios">
      <ion-back-button icon="" text="" class="white-icon"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">{{comboItem.master_menu_item_name}}</ion-title>
    <div>
      <div *ngIf="terminal_modal == 'true'"  class="terminal-overlay"></div>
      <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content (click)="reset_time()" [scrollEvents]="true" (ionScrollStart)="logScrollStart($event)"
  (ionScroll)="logScrolling($event)" (ionScrollEnd)="logScrollEnd($event)">
  <section class="main-box">
    <!-- !E_M_ON -->
    <div *ngIf="Ella_status == 'false'">
      <div class="item-box mt-5">
        <div class="select-drink">
          <ion-radio-group value = "{{radioValue}}">
            <div class="multipal">
              <div class="item-box" *ngFor="let item of itemList">
                <ion-radio *ngIf="item?.master_item_is_sold == 'NO'" slot="start"
                  value="{{item?.master_menu_item_uuid}}" 
                  (click)="getProductDetail(item.master_menu_item_uuid,1,comboItem.master_food_category_uuid)">
                </ion-radio>
                <span  [ngClass]="[item?.master_item_is_sold =='YES'? 'sold-out-img':'',radioValue != ''? 'deSelect':'']" ><img
                    src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}"
                    alt=""></span>
                    <!-- <span *ngIf="radioValue != ''" class="deSelect"><img
                      src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}"
                      alt=""></span>
                      <span *ngIf="radioValue == ''"><img
                        src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}"
                        alt=""></span> -->
                <h4>{{item.master_menu_item_name ? item.master_menu_item_name : '--'}}
                </h4>
                <div  *ngIf="item?.master_item_is_sold == 'YES' ">
                  <span class="soldOut-overlay"><img
                    src="../../assets/image/sold_out_overlay.png"
                    alt=""></span>
                  <!-- <p class="sold">  {{ 'HOME.sold_out' | translate:params }}</p> -->
                </div>
                <p *ngIf="showDecimal && item?.master_menu_item_price > 0 && item?.master_item_is_sold == 'NO' ">
                  {{currency}}{{item?.master_menu_item_price | number:'1.2-2'}}</p>
                <p
                  *ngIf="showDecimal == false && item?.master_menu_item_price > 0 && item?.master_item_is_sold == 'NO' ">
                  {{currency}}{{item?.master_menu_item_price | number:'1.0-0'}}</p>
              </div>
            </div>
          </ion-radio-group>
        </div>
      </div>
    </div>
    <!-- E_M_ON  -->
     <div *ngIf="Ella_status == 'true'">
      <div class="item-box mt-5">
        <div class="select-drink">
          <ion-radio-group>
            <div class="multipal">
              <div *ngFor="let item of itemList;let i = index;"
                [ngClass]="item.master_menu_type == 'ELLA' ?'item-box-none': 'item-box'">
                <div *ngIf="item.master_menu_type != 'ELLA' ">
                  <ion-radio *ngIf="item?.master_item_is_sold == 'NO' && item.master_menu_type != 'ELLA' " slot="start"
                    value="{{item?.master_menu_item_uuid}}"    
                    (click)="getProductDetail(item.master_menu_item_uuid,1,comboItem.master_food_category_uuid)">
                  </ion-radio>
                  <span [ngClass]="[item?.master_item_is_sold =='YES'? 'sold-out-img':'',radioValue != ''? 'deSelect':'']"><img *ngIf="item.master_menu_type != 'ELLA'"
                      src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}"
                      alt=""></span>
                  <h4 *ngIf="item.master_menu_type != 'ELLA' ">{{item.master_menu_item_name ? item.master_menu_item_name
                    : '--'}}
                  </h4>
                  <p class="sold" *ngIf="item?.master_item_is_sold == 'YES' && item.master_menu_type != 'ELLA'">{{
                    'HOME.sold_out' | translate:params }}</p>
                  <p
                    *ngIf="showDecimal && item?.master_menu_item_price > 0 && item?.master_item_is_sold == 'NO' && item.master_menu_type != 'ELLA' ">
                    {{currency}}{{item?.master_menu_item_price | number:'1.2-2'}}</p>
                  <p
                    *ngIf="showDecimal == false && item?.master_menu_item_price > 0 && item?.master_item_is_sold == 'NO' && item.master_menu_type != 'ELLA' ">
                    {{currency}}{{item?.master_menu_item_price | number:'1.0-0'}}</p>
                </div>
              </div>
            </div>
          </ion-radio-group>
        </div>
      </div>
    </div> 
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar mode="md">
    <ion-title>
      <ion-button *ngIf="total && radioValue == ''" class="d-flex align-items-center" (click)="addToCart(item)">
        <span *ngIf="showDecimal">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}} {{total | number:'1.2-2'}}
        </span>
        <span *ngIf="showDecimal == false">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}} {{total | number:'1.0-0'}}
        </span>
      </ion-button>
      <ion-button *ngIf="radioValue != ''"
        class="d-flex align-items-center" disabled>
        <span *ngIf="showDecimal">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}}
          {{comboItem.master_menu_item_kiosk_combo_price | number:'1.2-2'}}
        </span>
        <span *ngIf="showDecimal == false">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}}
          {{comboItem.master_menu_item_kiosk_combo_price | number:'1.0-0'}}
        </span>
      </ion-button>
      <!-- <ion-button *ngIf="!total && item.master_food_category_uuid == '0ecd423c3da7fa02266ae19af4b4cdb'"
        class="d-flex align-items-center" disabled>
        <span *ngIf="showDecimal">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}} {{comboItem.master_menu_item_kiosk_price |
          number:'1.2-2'}}
        </span>
        <span *ngIf="showDecimal == false">
          {{ 'PRODUCT_DETAIL.add_to_cart' | translate:params }} {{currency}} {{comboItem.master_menu_item_kiosk_price |
          number:'1.0-0'}}
        </span>
      </ion-button> -->
    </ion-title>
  </ion-toolbar>
</ion-footer>