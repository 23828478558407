import { Component, OnInit } from '@angular/core';
import { KioskApiService } from '../services/kiosk-api.service';

import { KioskauthService } from '../services/kioskauth.service';
import { TranslateConfigService } from '../services/translate-config.service';

@Component({
  selector: 'app-tv-screen',
  templateUrl: './tv-screen.page.html',
  styleUrls: ['./tv-screen.page.scss'],
})
export class TvScreenPage implements OnInit {
  order_response = {};

  constructor(
    private kioskauthservice:KioskauthService,
    private translateConfigService: TranslateConfigService,
    private kiosk_apiService: KioskApiService
    ) { 
      this.translateConfigService.getDefaultLanguage();
     
    }

    ngOnInit() {
     
      this.getOrders();
      setInterval(()=>{  
        this.getOrders();
      }, 1000);
    }

    slideOpts = {
      initialSlide: 1,
      speed: 400,
      pagination: false,
      loop: true,
      autoplay:true
    };
  
    ionViewWillEnter(){
   

     }
  
  
     getOrders(){

      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
      let payload = {"master_restaurant_uuid":master_restaurant_uuid}
      this.kiosk_apiService.getOrderStatus(payload)
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          let data = response.aaData;
          // console.log("  RESPONSE ORDERS: ");
          // console.log(data);
          this.order_response = data;
        }
        
      });

    }

   
  
    go_to_maintenance() {
      let terminalid = localStorage.getItem('terminalid')
      terminalid = JSON.parse(terminalid)
      location.href = "http://192.168.10.11:9001/?terminalid="+ terminalid;
    }
}
