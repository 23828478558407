import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { KioskauthService } from './services/kioskauth.service';

const routes: Routes = [
  {
    path: 'home',
    canActivate : [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'scan',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  
  {
    path: 'product-detail',
    canActivate : [AuthGuard],
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'payment-method',
    canActivate : [AuthGuard],
    loadChildren: () => import('./payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'settlement-complete',
    canActivate : [AuthGuard],
    loadChildren: () => import('./settlement-complete/settlement-complete.module').then( m => m.SettlementCompletePageModule)
  },
  {
    path: 'kioskautologin/:id/:uuid/:tid',
    loadChildren: () => import('./kioskautologin/kioskautologin.module').then( m => m.KioskautologinPageModule),
    pathMatch: 'full'
  },  
  {
    path: 'tv-screen',
    canActivate : [AuthGuard],
    loadChildren: () => import('./tv-screen/tv-screen.module').then( m => m.TvScreenPageModule)
  },
  {
    path: 'product-combo-detail',
    canActivate : [AuthGuard],
    loadChildren: () => import('./product-combo-detail/product-combo-detail.module').then( m => m.ProductComboDetailPageModule)
  },
  {
    path: 'autologin/1',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'scan',
    canActivate : [AuthGuard],
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'cart-list',
    canActivate : [AuthGuard],
    loadChildren: () => import('./cart-list/cart-list.module').then( m => m.CartListPageModule)
  },
  {
    path: 'combo-addons',
    canActivate : [AuthGuard],
    loadChildren: () => import('./combo-addons/combo-addons.module').then( m => m.ComboAddonsPageModule)
   },
    {
      path: 'payment-types',
      loadChildren: () => import('./payment-types/payment-types.module').then( m => m.PaymentTypesPageModule)
    },
    {
      path: 'qr-code-methods',
      loadChildren: () => import('./qr-code-methods/qr-code-methods.module').then( m => m.QrCodeMethodsPageModule)
    },
    {
      path: 'qr-scan',
      loadChildren: () => import('./qr-scan/qr-scan.module').then( m => m.QrScanPageModule)
    },
    {
      path: 'retry-payment',
      loadChildren: () => import('./retry-payment/retry-payment.module').then( m => m.RetryPaymentPageModule)
    },
    {
      path: 'no-payment-screen',
      loadChildren: () => import('./no-payment-screen/no-payment-screen.module').then( m => m.NoPaymentScreenPageModule)
    },
    {
      path: 'dbs-qr-scan',
      loadChildren: () => import('./dbs-qr-scan/dbs-qr-scan.module').then( m => m.DbsQrScanPageModule)
    },
    {
      path: 'payment-success-modal',
      loadChildren: () => import('./payment-success-modal/payment-success-modal.module').then( m => m.PaymentSuccessModalPageModule)
    },
  
  {
    path: '**',
    redirectTo: 'scan'
 },
  


 
  

 
 



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [KioskauthService,AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
