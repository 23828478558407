<ion-header>
  <ion-toolbar mode="md">
    <ion-buttons slot="start" mode="ios">
      <ion-button icon="" text="" (click)="cancelOrder()"   class="white-icon"></ion-button>
    </ion-buttons>
    <ion-title mode="ios" >Payment Method</ion-title>
    <div>
      <div *ngIf="terminal_modal == 'true'"  class="terminal-overlay"></div>
      <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>  
  <h2 *ngIf="timerTodisplay > 0">Tap your Credit Card  to complete the order  in {{ timerTodisplay}} seconds</h2> 
  <h2 *ngIf="timerTodisplay <= 0">Tap your Credit Card  to complete the order  in 0 seconds</h2> 
  <div class="paymentMethodArea">
    <div *ngIf="timerTodisplay <= 0" class="paymentLoader">
      <div class="nb-spinner"></div>
    </div>
    <img src="assets/image/card-scan.svg" alt="" class="logo">
  <img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/152420430520220329093708.gif" alt="" class="scan">

  <div *ngIf = "test_button_status">
  <ion-button (click)="TestCreatePayment('SUCCESS')"> TEST ORDER for  $ {{totalAmount}} </ion-button>
  <ion-button (click)="TestCreatePayment('CANCEL')"> TEST ORDER for  $ {{totalAmount}} </ion-button>
  <ion-button (click)="TestCreatePayment('FAILURE')" color="danger">Payment Failed</ion-button>
</div>
  </div>
  

<div class="ellaLimitSection">
  <div class="ellaLimitInner">
    <div class="limitIcon">
      <img src="assets/image/ellaLimitBell.svg" alt="">
    </div>
    <div class="limitText">
      Please wait for the transaction to be approved, only then remove your credit/debit card from the terminal!
    </div>
  </div>
</div>


</ion-content>

<ion-footer>
<ion-toolbar>
    <ion-title mode="md">
      <ion-button (click)="cancelOrder()" [disabled]="timerTodisplay <= 0">Cancel</ion-button>
    </ion-title>
  </ion-toolbar>
</ion-footer>