<ion-content (click)="reset_time()" [scrollEvents]="true" (ionScrollStart)="logScrollStart($event)"
  (ionScroll)="logScrolling($event)" (ionScrollEnd)="logScrollEnd($event)">

  <section>
    <div *ngFor="let items1 of item['add_ons'] | keyvalue; index as k">
      <ion-list *ngFor="let add_on of items1.value | keyvalue">
        <ion-item class="head" lines="none">
          <ion-label>
            <h2 id="{{k}}">
              <button class="accordion-button">
                {{add_on.key}}
              </button>
            </h2>
          </ion-label>
        </ion-item>
        <div *ngIf="groupSectiontypeIsMultiple(add_on.value[0]['master_addon_group_selection_type']) == false">
          <ion-card class="full-line">
            <ion-radio-group value="{{addons_select[add_on.value[0]['master_addon_group_uuid']]}}" >
              <ion-item *ngFor="let addon of add_on.value" lines="none">
                <ion-radio (click)="selectValue($event,addon)" id="{{addon?.master_addon_internal_name}}" slot="start"
                  mode="md" value="{{addon?.master_addon_internal_name}}"></ion-radio>
                <ion-label>
                  {{addon?.master_addon_internal_name}}
                  <div  class="ml-auto" id="{{addon?.master_addon_internal_name}}">
                    <span *ngIf="addon?.master_addon_price > 0">
                      {{currency}}{{addon?.master_addon_price}}
                    </span>
                  </div>
                </ion-label>
              </ion-item>
            </ion-radio-group>
          </ion-card>
        </div>
        <div *ngIf="groupSectiontypeIsMultiple(add_on.value[0]['master_addon_group_selection_type'])">
          <ion-card class="full-line">
            <ion-item lines="none" *ngFor="let addon of add_on.value">
              <ion-label>
                {{addon?.master_addon_name}}
                <div class="ml-auto" id="{{addon?.master_addon_internal_name}}">
                  <span *ngIf="addon?.master_addon_price > 0">
                    {{currency}}{{addon?.master_addon_price}}
                  </span>
                </div>
              </ion-label>
              <ion-checkbox (click)="selectValue($event,addon)" id="{{addon?.master_addon_uuid}}" slot="start" mode="md"
                value="{{addon?.master_addon_internal_name}}">
              </ion-checkbox>
            </ion-item>
          </ion-card>
        </div>
      </ion-list>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar mode="md">
    <ion-title>
      <ion-row>
        <ion-col>
          <ion-button class="d-flex align-items-center cancel" (click)="dismiss()">
            Cancel
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="d-flex align-items-center" (click)="dismissModal()">
            Add
          </ion-button>
        </ion-col>

      </ion-row>
    </ion-title>
  </ion-toolbar>
</ion-footer>