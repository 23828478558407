import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, Router, RouterEvent, NavigationEnd } from "@angular/router";
import { AlertController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { KioskauthService } from '../services/kioskauth.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { KIOSK_AUTO_LOGIN, modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, terminalCheck } from '../common/api';
import { ComboAddonsPage } from '../combo-addons/combo-addons.page';
import { KioskApiService } from '../services/kiosk-api.service';
import { AuditLogService } from '../services/audit-log.service';


@Component({
  selector: 'app-product-combo-detail',
  templateUrl: './product-combo-detail.page.html',
  styleUrls: ['./product-combo-detail.page.scss'],
})
export class ProductComboDetailPage implements OnInit {
  params: any;
  selectedItems: any = [];
  comboItem: any = []
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {};
  selected_addons = [];
  formFields: any = {};
  addons: Array<string> = [];
  quantity = 1;
  total: number;
  itemprice: number = 0;
  itembaseprice: number = 0;
  comboPrice: number = 0;
  total_base_price: number;
  comboPriceArray: any = [];
  totalAddonPriceArray: any = [];
  totalQuantity: any = [];
  currency = "¥";
  showDecimal = true;
  bookmark_customization: any = {};
  itemList: any = [];
  selectedvalue: any = [];
  restaurant_status: any;
  Ella_status = "false";
  obj_itemdetail
  selected_popup_addons = [];
  obj_comboitemlist;
  restaurant_status_interval: any;
  restaurant_details_interval: any;
  terminalId;
  terminal_modal
  telemetry_poller
  radioValue : any;
  
  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private store: LocalStoreService,
    private kioskauthservice: KioskauthService,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private translateConfigService: TranslateConfigService,
    public alertController: AlertController,
    private kiosk_apiService: KioskApiService,
    private auditlogservice: AuditLogService
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }


  ngOnInit() {
    this.getComboItems();
    this.terminalId = this.store.get('terminalid')
    this.restaurant_details_interval = setInterval(
      () => {
        var master_restaurant_status = localStorage.getItem('restaurant')
        this.restaurant_status = JSON.parse(master_restaurant_status);


      }, 1000)


    this.restaurant_status_interval = setInterval(
      () => {

        if (this.restaurant_status.master_restaurant_status == "ELLA_M_ON") {
          this.Ella_status = "true";

        }
        else {
          this.Ella_status = "false";


        }

      }, 2000)
  }

  ionViewWillEnter() {
    this.reset_time();
    this.audit_log()
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);

  }


  getComboItems() {
    let discountFlag = "";
    if (this.route.snapshot.queryParamMap.get('from')) {
      discountFlag = "yes";
    }
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let payload = {
      "ref": "kiosk",
      "restaurant_uuid": master_restaurant_uuid,
      "combo_menu_uuid": this.route.snapshot.queryParamMap.get('id'),
      "discount_flag": discountFlag
    };
    this.obj_comboitemlist = this.kiosk_apiService.getComboItems(payload)
      .subscribe((response) => {
        let data = response.aaData;
        this.itemList = data.items;

        this.comboItem = data.combo;
        if (this.comboItem.master_food_category_uuid == '0ecd423c3da7fa02266ae19af4b4cdb') {
          this.comboPrice = this.comboItem.master_menu_item_kiosk_price;
        } else {
          this.comboPrice = this.comboItem.master_menu_item_kiosk_combo_price;
        }

        if (this.store.get('comboAddonPrice')) {
          this.comboPrice = +this.comboPrice + +this.store.get('comboAddonPrice');
        }

        if (response.statusCode != 200) {
          if (response.aaData == false) {
            this.technical_error()
          }
        }
        error => {
          this.technical_error()
        }
      });
  }

  getProductDetail(item_uuid, counter, master_food_category_uuid) {
    let discountFlag = "";
    if (this.route.snapshot.queryParamMap.get('from')) {
      discountFlag = "yes";
    }
    this.radioValue = ""
    let data = {
      "ref": "kiosk",
      master_menu_item_uuid: item_uuid,
      combo_food_category_uuid: master_food_category_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
      discount_flag: discountFlag
    };
    this.obj_itemdetail = this.kiosk_apiService.getProductDetail(data)
      .subscribe(res => {
        this.item = res.aaData;
        counter = counter - 1;
        this.openSheetModal(this.item);
        this.selectedItems[counter] = item_uuid;
        this.comboPriceArray[counter] = res.aaData.master_menu_item_price;
        this.total = this.comboPriceArray.reduce(function (a, b) { return +a + +b; }, 0);
        this.total = res.aaData.master_menu_item_price;
        this.total_base_price = Number(res.aaData.master_menu_item_price);
        this.itembaseprice = this.total;
        this.calculateTotal();
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
        error => {
          this.technical_error()
        }
      })

  }

  async openSheetModal(item) {
    clearInterval(this.time_left_redirection_counter)
    if (item.add_ons[0].length != 0) {
      const modal = await this.modalController.create({
        component: ComboAddonsPage,
        cssClass: 'please-select-modal',
        mode: 'md',
        componentProps: {
          data: item
        },

      });
      modal.onDidDismiss().then((modelData) => {
        this.reset_time()
        if (modelData.data != null || modelData.data != undefined ) {
          item = modelData.data;
          this.selected_popup_addons = item.selected_addon;
           let sum = this.comboPriceArray.reduce(function (a, b) { return +a + +b; }, 0);
          this.total = sum + +this.comboPrice + +this.itemprice;
          this.total = +this.total + +item.total_addon_price;
        }
        else{
          this.calculateTotal()
         this.radioValue = item.master_menu_item_uuid
         this.total =  +this.comboPrice ;
        }
      });
      return await modal.present();
    }else{
      //   let sum = this.comboPriceArray.reduce(function (a, b) { return +a + +b; }, 0);
      // this.total = sum + +this.comboPrice + +this.itemprice;
    }

  }

  calculateTotal() {
    // debugger; 
    this.totalQuantity = [];
    for (let i = 1; i <= this.quantity; i++) {
      this.totalQuantity.push(i);
    }
      let sum = this.comboPriceArray.reduce(function (a, b) { return +a + +b; }, 0);
      this.total = sum + +this.comboPrice + +this.itemprice;
   
  }


  async store_in_local(event, obj, counter) {
    if (counter > 0) {
      counter = counter - 1;
    }
    let master_addon_group_uuid = obj['master_addon_group_uuid'];
    if (obj.master_addon_group_selection_type == 'SINGLE') {
      if (this.bookmark_customization[master_addon_group_uuid]) {
        this.bookmark_customization[master_addon_group_uuid][0] = obj.master_addon_internal_name;
        this.selectedvalue = this.bookmark_customization[master_addon_group_uuid][0];
      }

    } else {
      let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
      if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {

        this.bookmark_customization[master_addon_group_uuid] = [];
      }

    }

    let addonsList = [];

    if (obj.master_addon_group_selection_type == "MULTIPLE") {
      if (event.target.checked) {
        if (obj.master_addon_name != null) {
          delete this.bookmark_customization[master_addon_group_uuid]

        }

      } else {
        let len = this.bookmark_customization[master_addon_group_uuid].length;
        if (obj.master_addon_name != null) {
          this.bookmark_customization[master_addon_group_uuid][len] = obj.master_addon_internal_name;

        }
        addonsList[counter] = this.bookmark_customization;
      }
    }

    addonsList[counter] = this.bookmark_customization;
    let existingData = this.store.get('formFields');
    if (existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);

    }
    this.calculateTotal();
  }

  addToCart(item) {
    
    let data1 = this.store.get('formFields');
    let comboItems = "";
    if (data1) {
      const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      if (this.route.snapshot.queryParamMap.get('ci')) {
        let cartIndex = this.route.snapshot.queryParamMap.get('ci');
        items.splice(cartIndex, 1);
        this.store.setUserCart(items);
      }

      let comboAddon = [];
      if (this.store.get('formFieldsCombo')) {
        comboAddon = this.store.get('formFieldsCombo');
      }
      let data: any;
      let finalArray: any = [];


      let combo_items = {};
      let comboItemCounter = 0;
      for (var i = 0; i < this.quantity; i++) {
        this.addons = [];
        if (data1[i] != null) {
          if (this.selected_popup_addons[0] != undefined) {
            this.addons = this.selected_popup_addons
          }
          else {
            this.addons = []
          }


          let menu_item_total_price = item.master_menu_item_price;
          let addOnAmount: number = 0;
          Object.entries(this.totalAddonPriceArray).forEach(([kk, vv]) => {
            let arrayCounter: any = 0;
            let addOnPriceKey = kk.split("_");
            arrayCounter = addOnPriceKey[0];
            if (arrayCounter == i) {
              addOnAmount = +vv;
              menu_item_total_price = +vv + +menu_item_total_price;
            }
          })

          combo_items[comboItemCounter] = {
            menu_item_uuid: this.selectedItems[i],
            id: this.selectedItems[i],
            combo_uuid: this.comboItem.master_menu_item_uuid,
            name: item.master_menu_item_name,
            thumb: item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
            type: item.master_menu_type,
            resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
            menu_item_total_price: this.total - this.comboItem.master_menu_item_price,
            item_addon_price: addOnAmount,
            menu_item_price: item.master_menu_item_price,
            total: this.total,
            total_base_price: this.total_base_price,
            quantity: this.quantity,
            addons: this.addons,
            note: this.store.note ? this.store.note : null,
            is_combo: "YES",
            is_main_combo_item: "NO",
            food_type: item.master_menu_type,
            total_quantity: this.totalQuantity.length,
            price:this.total - this.comboItem.master_menu_item_price
          };
          comboItemCounter++;
          // if (items) {
          //   finalArray.push(data);
          // } else {
          //   finalArray.push(data);
          // }
        }
        comboItems += "," + item.master_menu_item_name;
      }

      combo_items[comboItemCounter] = {
        menu_item_uuid: this.comboItem.master_menu_item_uuid,
        id: this.comboItem.master_menu_item_uuid,
        combo_uuid: this.comboItem.master_menu_item_uuid,
        name: this.comboItem.master_menu_item_name,
        thumb: this.comboItem.master_menu_item_thumbnail ? this.comboItem.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
        type: this.comboItem.master_menu_type,
        resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        menu_item_total_price: this.total ,
        item_addon_price: 0,
        menu_item_price: this.comboItem.master_menu_item_price,
        total: this.total,
        total_base_price: this.comboItem.master_menu_item_price,
        quantity: 1,
        addons: comboAddon,
        note: this.store.note ? this.store.note : null,
        is_combo: "YES",
        is_main_combo_item: "YES",
        food_type: this.comboItem.master_menu_type,
        total_quantity: this.totalQuantity.length,
        
      }

      let cData = {
        combo_items: combo_items,
        id: this.comboItem.master_menu_item_uuid,
        combo_uuid: this.comboItem.master_menu_item_uuid,
        name: this.comboItem.master_menu_item_name,
        thumb: this.comboItem.master_menu_item_thumbnail ? this.comboItem.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
        type: this.comboItem.master_menu_type,
        resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        menu_item_total_price: this.total,
        item_addon_price: 0,
        menu_item_price: this.comboItem.master_menu_item_price,
        total: this.total,
        total_base_price: this.comboItem.master_menu_item_price,
        quantity: 1,
        addons: comboAddon,
        note: this.store.note ? this.store.note : null,
        is_combo: "YES",
        is_main_combo_item: "YES",
        food_type: this.comboItem.master_menu_type,
        total_quantity: this.totalQuantity.length
      }
      //finalArray.push(cData);
      comboItems += this.comboItem.master_menu_item_name;



      if (items) {
        items.push(cData);
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(items);
        } else {
          this.store.setCart(items);
        }
      } else {
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(finalArray);
        } else {
          this.store.setCart([data]);
        }
      }
    }
    setTimeout(() => {
      this.auditlogservice.logEvents("ADD_TO_CART");
      this.auditlogservice.audit_log("ADD_TO_CART")
    }, 200);
    this.router.navigate([`home`]);
    return false;
  }




  ionViewDidLeave() {
    this.obj_itemdetail.unsubscribe();
    this.obj_comboitemlist.unsubscribe();
    clearInterval(this.telemetry_poller)
    clearInterval(this.terminal_modal)
    clearInterval(this.time_left_redirection_counter);
  }


  // go_to_maintenance() {
  //   location.href = maintenance_url;
  // }


  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
  }

  page_redirection_time_left = page_redirection_time;

  time_left_redirection_counter: any;

  reset_time() {

    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;

    if (this.page_redirection_time_left = page_redirection_time) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;

        if (this.page_redirection_time_left == 0) {
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.terminal_modal)
          clearInterval(this.telemetry_poller)
          clearInterval(this.time_left_redirection_counter);
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalId;
          return false;
        }
      }, setIntervalTimeOut);
    }


  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();

  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();

  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();

  }

  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [

        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/Scan');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/Scan'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

  audit_log() {
    this.auditlogservice.logEvents("PRODUCT_COMBO");
    this.auditlogservice.audit_log("PRODUCT_COMBO");
  }

}
