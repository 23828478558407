<ion-header  *ngIf="headerShow"
  (click)="reset_time()">
  <ion-toolbar>
    <ion-title>
      <div [innerHTML]="home_banner_image" class="homeBanner"></div>
    </ion-title>
    <!-- <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button> -->
    <div>
      <div *ngIf="terminal_modal == 'true'"  class="terminal-overlay"></div>
      <ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true" (click)="reset_time()" [scrollEvents]="true" (ionScrollStart)="logScrollStart($event)"
  (ionScroll)="logScrolling($event)" (ionScrollEnd)="logScrollEnd($event)">
  <!-- Screen Pull Refresh -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <!-- Screen Pull Refresh End-->
  <div *ngIf="shop && shop.master_restaurant_status === 'M_ON' && appSettings"
    [innerHTML]=" app_maintanance_description2" class="maintaince">
  </div>
  <div *ngIf="shop && shop.master_restaurant_status === 'ACTIVE' && appSettings  && is_restaurant_closed_status=='YES' "
     [innerHTML]="app_store_closed_description2"></div>
     
  <div *ngIf="headerShow">
    <section style="padding: 0 40px;">
      <ion-item lines="none" class="head">
        <ion-label>
          <img src="../../assets/image/hello.svg" alt="">
        </ion-label>
        <div class="ml-auto" style="display: flex;flex-direction: column;align-items: end;">
          <img src="../../assets/image/fuel-up.png" alt="">
        </div>
      </ion-item>
    </section>
    <div *ngIf="!menu_item" class="giff">
      <img src="https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/104772098220220418091620.gif"
        alt="">
    </div>
    <div *ngIf="menu_item">
      <section  class="top-fix" *ngIf="menuItems_length > 1">
        <ion-segment mode="ios" [(ngModel)]="type" scrollable
          *ngIf="shop && (shop.master_restaurant_status === 'ACTIVE' || shop.master_restaurant_status === 'ELLA_M_ON') && appSettings">
          <ion-segment-button *ngFor="let items of menuItems | keyvalue ; let i = index" value="{{i}}">
            <ion-label>{{items.key | category}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </section>
      <div [ngSwitch]="type" *ngFor="let items of menuItems | keyvalue ;let ii = index;">
        <div *ngSwitchCase="ii">
          <div
            *ngIf="shop && shop.master_restaurant_status === 'ELLA_M_ON' && appSettings  && is_category_coffee(items)=='YES' "
            [innerHTML]="app_ella_maintanance_description2"></div>
          <ion-row style="margin: 0;">
            
            <ion-col size="4" *ngFor="let item of items.value;let i=index;">
              <div class="box"
                *ngIf="(shop.master_restaurant_status === 'ACTIVE')  ||  (shop.master_restaurant_status === 'ELLA_M_ON' && is_category_coffee(items) !='YES'  )">
                <div class="sold_out" *ngIf="item.master_item_is_sold =='YES'">
                  <span class="sold-overlay"><img
                    src="../../assets/image/sold_out_overlay.png"
                    alt=""></span>
                  <!-- <p class="sold">{{ 'HOME.sold_out' | translate:params }}</p> -->
                </div>
                <div class="overlay"  *ngIf="item.master_item_is_sold =='YES'"></div>
                <div detail="false" [ngClass]="item.master_item_is_sold =='YES'? 'sold-out-img':''"   (click)="redirectOnDetailPage(item)">
                  <span><img
                      src="{{item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg'}}"
                      alt=""></span>
                  <h3>{{item.master_menu_item_name ? item.master_menu_item_name : '--'}}</h3>
                  <p *ngIf="item.master_item_is_sold == 'NO' && item.master_menu_item_kiosk_price > 0 && showDecimal">
                    {{currency}}{{item.master_menu_item_kiosk_price | number:'1.2-2'}} {{
                    'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</p>
                  <p
                    *ngIf="item.master_item_is_sold == 'NO' && item.master_menu_item_kiosk_price > 0 && showDecimal == false ">
                    {{currency}}{{item.master_menu_item_kiosk_price | number:'1.0-0'}} {{
                    'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</p>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf="cartItemCount > 0  && is_maintaince_mode_on==false " (click)="reset_time()">
  <span>
    <div *ngIf ="haveMaxEllaCoffee" class = "ellaAlert">
      <div class="ellaAlertstat">
        <img src="../../assets/image/ellaAlertBell.svg"> You have reached the {{ella_max_drinks}} Ella drinks order limit, please adjust/remove items in your cart.</div>
    </div>
    <ion-toolbar>
      <ion-title>
        <h4><img src="assets/image/order-icon.svg" alt=""> My Orders| <span>{{cartItemCount ? cartItemCount : '0'}}
            item(s)</span></h4>
        <div class="item-price">
          <div class="loop-box loop-conatin">
            <div class="loop-box-wrap">
              <div class="box" *ngFor="let item of cartItems;let i = index">
                <img *ngIf="item['is_combo'] == 'YES'" (click)="openSheetModal(item)"
                  src="../../assets/image/information.png" alt="" class="imfomation">
                <span *ngIf="item['is_combo'] == 'YES'" (click)="openSheetModal(item)">
                  <button (click)="removeItemFromCart(i)"><img src="assets/image/delete.svg" alt=""></button>
                  <img *ngIf="item['is_combo'] == 'YES'" src="{{item?item['thumb'] : ''}}" alt="">
                  <img *ngIf="item['is_combo'] != 'YES'" src="{{item?item['thumb'] : ''}}" alt="">
                </span>
                <span *ngIf="item['is_combo'] != 'YES'">
                  <button (click)="removeItemFromCart(i)"><img src="assets/image/delete.svg" alt=""></button>
                  <img *ngIf="item['is_combo'] == 'YES'" src="{{item?item['thumb'] : ''}}" alt="">
                  <img *ngIf="item['is_combo'] != 'YES'" src="{{item?item['thumb'] : ''}}" alt="">
                </span>
                <h3 *ngIf="item['is_combo'] == 'YES'">
                  <p>{{getTotalQuantity(item)}} x {{item['combo_items'][0]?item['combo_items'][0]['name']:''}} </p>
                  <p>{{getTotalQuantity(item)}} x {{item['combo_items'][1]?item['combo_items'][1]['name']:''}} </p>
                </h3>
                <h3 *ngIf="item['is_combo'] != 'YES'">{{getTotalQuantity(item)}} x {{item?item['name']:''}}</h3>
                <p *ngIf="showDecimal">{{currency}} {{item['total'] | number:'1.2-2'}} {{
                  'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</p>
                <p *ngIf="showDecimal == false">{{currency}} {{item['total']| number:'1.0-0'}} {{
                  'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</p>
              </div>
            </div>
            <a class="arrow" *ngIf="cartItems?.length > 2">></a>
          </div>
          <div class="price ml-auto">
            <div class="cart-timer" style="display: none;">
              <img src="assets/image/timer.svg" alt=""> 0:{{(time_left < 10) ? '0' +time_left : '' +time_left}} </div>
                <h3>Total
                  <span *ngIf="showDecimal">{{currency}} {{totalCartAmount | number:'1.2-2'}} {{
                    'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</span>
                  <span *ngIf="showDecimal == false">{{currency}} {{totalCartAmount | number:'1.0-0'}} {{
                    'PLEASE_SELECT.include_tax_with_item_price' | translate:params }}</span>
                </h3>
                <!-- <div *ngIf ="!haveMaxEllaCoffee" class="offer"  (click)="isItemCartAvaliable()"> -->
                  <div *ngIf ="!haveMaxEllaCoffee" class="offer"  (click)="goToCartList()">
                  <img src="../../assets/image/pointer.png" alt="" class="pointer">
                  Check out
                </div>
                <div *ngIf ="haveMaxEllaCoffee" class="checkout_disabled">
                 
                </div>
            </div>
          </div>
      </ion-title>
    </ion-toolbar>
  </span>
</ion-footer>

<!-- <ion-footer 
  mode="md" (click)="reset_time()">
  <div class="footerInner" *ngIf="cartItemCount > 0 && is_maintaince_mode_on==false">
      <div class="footerLeft">
        <div class="cupIcon">
          <img src="../../assets/image/IconCoffeeCupGreen.png">
          <span class="countBadge">{{cartItemCount}}</span>
        </div>
      </div>
      <div class="footerRight">
        <div class="footerSummery">
          <div class="grantTotal">
            <div class="grandTotalLabel">
              <h2>Order Total</h2>
              <h3>SGD {{currency}}{{totalCartAmount | number:'1.2-2'}}</h3>
            </div>
            <div class="resetCart">
              <div class="resetButton" (click)="resetCart()">
                Reset <img src="../../assets/image/resetIconGreen.png">
              </div>
            </div>
          </div>
          <div class="checkOutButton">
            <button class="handClickButton" (click)="goToCartList()">View Cart
                <span *ngIf="page_redirection_time_left <= 20" class="handClickIcon">
                <img src="../../assets/image/Handclick.gif">
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
</ion-footer> -->