export const environment = {
  production: true,
  base_url: "https://https://order.crowndigital.io/web/v1",
  M_ON: `<img src="./../../assets/image/maintainance_mode.gif">`,
  ELLA_M_ON: `<img src="./../../assets/image/Kiosk-cleaning-Screen.jpg">`,
  STORE_CLOSED: `<img src="./../../assets/image/BS_kiosk_maintainance_mode.gif">`,
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyDpgYsUVFAqZOF5cLVgZIxj0fYknJtAlNk",
    authDomain: "staging-ella-app.firebaseapp.com",
    projectId: "staging-ella-app",
    storageBucket: "staging-ella-app.appspot.com",
    messagingSenderId: "893373876771",
    appId: "1:893373876771:web:25854ef09cd626899bbf96",
    measurementId: "G-XDKF9XRFRC"
    },
    key_for_sha1: "hp8d1xxkiq1ib4hyex3po438vtfzkbaw"
};
