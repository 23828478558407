import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {

  constructor(  
      private router: Router,
      private route: ActivatedRoute,
      private store:LocalStoreService) {
      this.store.hardlogout();
      this.router.navigate([`scan`]);  
   }

  ngOnInit() {
    this.store.hardlogout();
    this.router.navigate([`scan`]);  
  }

}
