import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { KIOSK_AUTO_LOGIN, page_redirection_time, setIntervalTimeOut } from '../common/api';

import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateConfigService } from '../services/translate-config.service';

@Component({
  selector: 'app-combo-addons',
  templateUrl: './combo-addons.page.html',
  styleUrls: ['./combo-addons.page.scss'],
})
export class ComboAddonsPage implements OnInit {
  @Input() data: any;
  currency = "S$";
  showDecimal = true;
  item = [];
  addons_total_prices: any;
  addons_prices : any = {};
  final_addon = [];
  addons_price = [];
  page_redirection_time_left = page_redirection_time;
  time_left_redirection_counter: any;
  pre_selected_addons: any;
  addons_selected: Array<string> = []
  addons_price_selected: Array<string> = []
  addons_select:any = {};
  terminalId:any;

  constructor(public modalCtrl: ModalController,
    private store: LocalStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private translateConfigService: TranslateConfigService) {
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  groupSectiontypeIsMultiple(inputType) {
    if (inputType == 'MULTIPLE') {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    if (this.data) {
      this.item = this.data;
      this.pre_selected_addons = JSON.parse(this.data.bookmark_customization)
      this.store.set('preSelectaddons',this.pre_selected_addons)
      // if(this.data.bookmark_customization == '[]'){
      //   this.pre_selected_addons= []
      // }
    }

    this.get_prefilled_values();
    this.reset_time()
    this.terminalId = this.store.get('terminalid')
  }

  get_prefilled_values() {
   let addons = Object.create( {} );
    for (var k in this.data.add_ons) {
      let rows = this.data.add_ons[k];     
      for (var a in rows) {
        if (rows.hasOwnProperty(a)) {
          let row = rows[a];
          // let master_addon_group_uuid = row[0].master_addon_group_uuid;
          let addonPreValue = this.store.get('preSelectaddons')
            this.addons_select =  addonPreValue
        }
      }
    }
  }

  // selectValue_(event, obj) {
  //   let master_addon_group_uuid = obj.master_addon_group_uuid
  //   if (obj.master_addon_group_selection_type == 'SINGLE') {
  //     this.addons_price[master_addon_group_uuid] = []
  //     this.addons_select[master_addon_group_uuid] = []
  //     this.addons_select[master_addon_group_uuid][0] =  obj.master_addon_internal_name
  //     this.addons_price[master_addon_group_uuid][0] = obj.master_addon_price;
  //     console.log("==============>>>>>>",  this.addons_select)
  //   }
  //   else {
  //     if (event.target.checked) {
  //       delete  this.addons_select[master_addon_group_uuid]
  //       delete  this.addons_price[master_addon_group_uuid]
  //     }
  //     else {
  //       let len = this.addons_select.length
  //       if(obj.master_addon_name != null){
  //         this.addons_select[master_addon_group_uuid] = []
  //         this.addons_select[master_addon_group_uuid][len+1] = obj.master_addon_internal_name;
  //         this.addons_price[master_addon_group_uuid] = []
  //         this.addons_price[master_addon_group_uuid][0] = obj.master_addon_price;
         
  //       }
  //     }
  //   }
  // }

  selectValue(event,obj){
    let master_addon_group_uuid = obj.master_addon_group_uuid;
    if (obj.master_addon_group_selection_type == 'SINGLE') {
      if(this.addons_select[master_addon_group_uuid]) {
        this.addons_price[master_addon_group_uuid] = []
        this.addons_price[master_addon_group_uuid][0] = obj.master_addon_price;
        this.addons_select[master_addon_group_uuid][0] = obj.master_addon_internal_name;
      }
    }
    else {
      let type_of_bookmark = typeof this.addons_select[master_addon_group_uuid];
      if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {
       
        this.addons_select[master_addon_group_uuid] = [];
      }
      
      
    }

    if(obj.master_addon_group_selection_type == "MULTIPLE") {
      if (event.target.checked) {
        delete  this.addons_select[master_addon_group_uuid]
        delete  this.addons_price[master_addon_group_uuid]
      }
      else {
        let len = this.addons_select.length
        if(obj.master_addon_name != null){
          this.addons_select[master_addon_group_uuid] = []
          this.addons_select[master_addon_group_uuid][len+1] = obj.master_addon_internal_name;
          this.addons_price[master_addon_group_uuid] = []
          this.addons_price[master_addon_group_uuid][0] = obj.master_addon_price;
         
        }
      }
    }
  }

  dismissModal() { 
    Object.entries(this.addons_select).forEach(([k, v]) => {
     Object.entries(v).forEach(([kk,vv])=>{
       let addOnName: any= "";
                      addOnName = vv;
                      if(addOnName != null) {
                        this.addons_selected.push(addOnName);
                      }
     })
    });
    // console.log(this.addons_price)
    Object.entries(this.addons_price).forEach(([k, v]) => {
      Object.entries(v).forEach(([kk,vv])=>{
        let addOnName: any= "";
                       addOnName = vv;
                       if(addOnName != null) {
                         this.addons_price_selected.push(addOnName);
                       }
      })
     });
     let price = this.addons_price_selected.map(i => Number(i));
      this.addons_total_prices = price.reduce((acc, cur) => acc + cur, 0);
      this.final_addon['selected_addon'] = this.addons_selected;

      this.final_addon['total_addon_price'] = this.addons_total_prices;

    // console.log("final_addon",this.final_addon)
    this.modalCtrl.dismiss(this.final_addon);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  reset_time() {
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;
    if (this.page_redirection_time_left = page_redirection_time) {
      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.modalCtrl.dismiss();
          this.store.setUserCart([]);
          this.store.remove("preSelectaddons");
          this.store.remove("cart");
          let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }

  ionViewDidLeave() {
    clearInterval(this.time_left_redirection_counter);
  }

}





