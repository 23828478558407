import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ComboAddonsPageRoutingModule } from './combo-addons-routing.module';

import { ComboAddonsPage } from './combo-addons.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComboAddonsPageRoutingModule
  ],
  declarations: [ComboAddonsPage]
})
export class ComboAddonsPageModule {}
